<template>
  <div>
    <div class="bodyer">
      <div class="main-context">
        <div class="bodyer-box">
          <div class="title">
            政府、企业、高校、金融
          </div>
          <div class="sub-title">
            礼品类一站式解决方案提供商
          </div>
          <div class="logos">
            <div class="logo-item logo1" />
            <div class="logo-item logo2" />
            <div class="logo-item logo3" />
            <div class="logo-item logo4" />
            <div class="logo-item logo5" />
            <div class="logo-item logo6" />
            <div class="logo-item logo7" />
            <div class="logo-item logo8" />
            <div class="logo-item logo9" />
            <div class="logo-item logo10" />
            <div class="logo-item logo11" />
            <div class="logo-item logo12" />
            <div class="logo-item logo13" />
            <div class="logo-item logo14" />
            <div class="logo-item logo15" />
          </div>
        </div>
        <div class="bodyer-box">
          <div class="title">
            好、快、优、省
          </div>
          <div class="sub-title">
            大力开发终端市场,为客户提供优质的服务,减少客户中间环节,真正做到瘦身提效,保证客户在降低采购成本的同时提高工作效率
          </div>
          <div class="imgs">
            <div class="left">
              <div class="img-item img1" />
              <div class="img-item img2" />
              <div class="img-item img4" />
              <div class="img-item img5" />
            </div>
            <div class="right img3" />
          </div>
        </div>
        <div class="bodyer-box">
          <div class="title">
            10,000+款精选SKU涵盖12大品类<br>全品类一站式购齐
          </div>
          <div class="sub-title">
            水果、蔬菜、肉蛋、水产、乳品、零食、酒饮、熟食、轻食、速食、粮油、日百
          </div>
          <div class="foods">
            <div class="food-item food1">
              水果
            </div>
            <div class="food-item food2">
              蔬菜
            </div>
            <div class="food-item food3">
              肉蛋
            </div>
            <div class="food-item food4">
              水产
            </div>
            <div class="food-item food5">
              乳品
            </div>
            <div class="food-item food6">
              零食
            </div>
            <div class="food-item food7">
              酒饮
            </div>
            <div class="food-item food8">
              熟食
            </div>
            <div class="food-item food9">
              轻食
            </div>
            <div class="food-item food10">
              速食
            </div>
            <div class="food-item food11">
              粮油
            </div>
            <div class="food-item food12">
              日百
            </div>
          </div>
        </div>
        <div class="bodyer-apply">
          <div class="apply-info">
            <div class="title">
              礼享购供应商
            </div>
            <div class="sub-title">
              加入申请函
            </div>
            <div class="apply-inputs">
              <div class="inputs">
                <div class="apply-input">
                  <el-input v-model="inputUserName" placeholder="姓名" />
                </div>
                <div class="apply-input">
                  <el-input v-model="inputRegionName" placeholder="服务区域" />
                </div>
                <div class="apply-input">
                  <el-input v-model="inputUserPhone" placeholder="联系电话" />
                </div>
                <div class="apply-input">
                  <el-cascader
                    v-model="selectedRegionOptions"
                    style="width: 100%"
                    placeholder="所在城市"
                    :options="options"
                    @change="handleRegionChange"
                  />
                </div>
              </div>
              <div class="apply-button" @click="applyInfo">
                立即申请
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from 'element-china-area-data'
import { create } from '@/api/applyInfo'

export default {
  data() {
    return {
      options: regionData,
      inputUserName: '',
      inputRegionName: '',
      inputUserPhone: '',
      selectedRegionOptions: [],
      inputCityName: ''
    }
  },
  methods: {
    handleRegionChange() {
      this.inputCityName = this.selectedRegionOptions.map(m => {
        return CodeToText[m]
      }).join(',')
    },
    applyInfo() {
      if (this.inputUserName === '' || this.inputUserPhone === '' || this.inputCityName === '') {
        this.$message({
          message: '请完整填写申请函信息，感您的支持，谢谢！',
          type: 'warning'
        })
        return
      }

      create({
        name: this.inputUserName,
        phone: this.inputUserPhone,
        area: this.inputRegionName,
        city: this.inputCityName
      }).then(response => {
        this.$message({
          duration: 5000,
          message: '申请函信息已提交，我们将在3个工作日内与您联系，感您的支持，谢谢！',
          type: 'success'
        })

        this.inputUserName = ''
        this.inputUserPhone = ''
        this.inputRegionName = ''
        this.inputCityName = ''
        this.selectedRegionOptions = []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .bodyer {
    .bodyer-box {
      text-align: center;
      padding-top: 200px;

      .title {
        font-size: 48px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #17254c;
      }

      .sub-title {
        width: 1050px;
        font-size: 30px;
        font-weight: 400;
        margin: 0 auto 20px;
        color: #bec3d2;
      }

      .imgs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: flex-start;
        height: 500px;

        .left {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          align-content: flex-start;
          align-items: flex-start;
          flex: 0 0 66.66%;
          margin: -5px 0 0 -5px;
          .img-item {
            flex: 0 0 49%;
            height: 247px;
            margin: 5px 0 0 5px;
          }
        }

        .right {
          width: 33.33%;
          height: 100%;
        }

        .img1 {
          background: url("../assets/images/kelly-sikkema-94_X77ZnRRo-unsplash@2x.png")
            center top / 50% no-repeat;
          background-size: cover;
        }

        .img2 {
          background: url("../assets/images/快递@2x.png") center center no-repeat;
          background-size: cover;
        }

        .img3 {
          background: url("../assets/images/购物车@2x.png") center top no-repeat;
          background-size: cover;
        }

        .img4 {
          background: url("../assets/images/产品展示@2x.png") center top no-repeat;
          background-size: cover;
        }

        .img5 {
          background: url("../assets/images/供应车@2x.png") center center
            no-repeat;
          background-size: cover;
        }
      }

      .logos {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;

        .logo-item {
          flex: 0 0 20%;
          height: 160px;
        }

        .logo1 {
          background: url("../assets/images/中粮@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo2 {
          background: url("../assets/images/三只@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo3 {
          background: url("../assets/images/万达@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo4 {
          background: url("../assets/images/华为@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo5 {
          background: url("../assets/images/大润@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo6 {
          background: url("../assets/images/宝洁@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo7 {
          background: url("../assets/images/中国国旅@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo8 {
          background: url("../assets/images/联合@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo9 {
          background: url("../assets/images/美的@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo10 {
          background: url("../assets/images/携程@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo11 {
          background: url("../assets/images/五印@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo12 {
          background: url("../assets/images/南极@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo13 {
          background: url("../assets/images/真彩@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo14 {
          background: url("../assets/images/鲁花@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .logo15 {
          background: url("../assets/images/希尔顿@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
      }

      .foods {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: space-evenly;

        .food-item {
          height: 200px;
          width: 170px;
          border: dashed 1px #888;
          margin-bottom: 20px;

          padding-top: 15px;
          font-size: 20px;
          font-weight: 400;
          color: #c9c9c9;
        }

        .food1 {
          background: url("../assets/images/菠萝@2x@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .food2 {
          background: url("../assets/images/竹笋@2x@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .food3 {
          background: url("../assets/images/肉1@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .food4 {
          background: url("../assets/images/鱼@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .food5 {
          background: url("../assets/images/肉组合@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .food6 {
          background: url("../assets/images/油瓶@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .food7 {
          background: url("../assets/images/沙拉2@2x@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .food8 {
          background: url("../assets/images/渐变填充 2@2x.png") center center /
            50% no-repeat;
          background-size: cover;
        }
        .food9 {
          background: url("../assets/images/包装组合@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .food10 {
          background: url("../assets/images/酒瓶@2x@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
        .food11 {
          background: url("../assets/images/渐变填充 3@2x.png") center center /
            50% no-repeat;
          background-size: cover;
        }
        .food12 {
          background: url("../assets/images/纸@2x.png") center center / 50%
            no-repeat;
          background-size: cover;
        }
      }
    }

    .bodyer-apply {
      display: flex;
      justify-content: center;
      text-align: center;
      padding-top: 100px;

      .apply-info {
        width: 752px;
        height: 488px;
        padding-top: 66px;
        background: url("../assets/images/彩带1@2x.png") no-repeat;
        background-size: cover;

        .title {
          font-size: 24px;
          font-weight: 400;
          color: #cfba94;
          opacity: 0.8;
        }

        .sub-title {
          font-size: 32px;
          font-weight: 400;
          color: #cfba94;
        }

        .apply-inputs {
          .inputs {
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
            justify-content: space-evenly;
            padding: 35px 90px 40px;

            .apply-input {
              flex: 0 0 40%;
              height: 48px;
            }
          }

          .apply-button {
            margin: 0 auto;
            width: 400px;
            height: 70px;
            line-height: 70px;
            background: linear-gradient(0deg, #bda476 0%, #d4c2a4 100%);
            border-radius: 6px;

            font-size: 24px;
            font-weight: bold;
            color: #ffffff;

            cursor: pointer;
          }
        }
      }
    }
  }
</style>
